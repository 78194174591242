<template>
  <router-view />
</template>

<script>
import { parentModule, eventsModule } from '@/store/modules';

export default {
  name: 'StudentsWrapper',
  methods: {
    ...parentModule.mapActions(['getStudents']),
    ...eventsModule.mapActions(['getDailyEvents']),
  },
  async created() {
    await Promise.all([this.getStudents(), this.getDailyEvents()]);
  },
};
</script>
